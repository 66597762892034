import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import PortfolioLanding from "./App";
import "./index.css";
import "./index.scss";

ReactDOM.render(
  <BrowserRouter>
    <PortfolioLanding />
  </BrowserRouter>,
  document.getElementById("root")
);